import React, { Fragment, useState } from 'react';
import Header from '../../components/header/Header';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import aImg from '../../images/about/about_image_3.webp'
import aImg2 from '../../images/about/about_image_4.webp'
import ModalVideo from 'react-modal-video'
import PolicySection from './Policy';
import WhyUs from './WhyUs';
// import FeaturesSection from '../../components/FeaturesSection/FeaturesSection';
// import TeamSection from '../../components/TeamSection/TeamSection';
import CtaSection from '../../components/CtaSection/CtaSection';


const AboutUsPage = (props) => {

    const [isOpen, setOpen] = useState(false)
    return (
        <Fragment>
            <Header />
            <main className="page_content about-page">
                <PageTitle pageTitle={'About Us'} pagesub={'More Drrived 😃'} pageTop={'About'}/>
                <section className="intro_about_section section_space bg-light">
                    <div className="intro_about_content">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="image_wrap">
                                        <img src={aImg} alt="Drrived - About " />
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="image_wrap position-relative">
                                        <img src={aImg2} alt="Drrived - About" />
                                        <button className="video_btn ripple_effect" onClick={() => setOpen(true)}>
                                            <span className="btn_icon">
                                                <i className="fa-solid fa-play"></i>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="heading_block mb-0">
                            <div className="row justify-content-lg-between">
                                <div className="col-lg-4">
                                    <div className="heading_focus_text">
                                        About
                                        <span className="badge bg-secondary text-white">Drrived 🙂</span>
                                    </div>
                                    <h2 className="heading_text mb-0">Your Trusted Partner in Cloud Solutions</h2>
                                </div>
                                <div className="col-lg-7">
                                    <p className="heading_description mb-0">At Drrived, we specialize in comprehensive cloud solutions designed to enhance your digital experience. Our services encompass Cloud File Storage, ensuring your data is securely stored and easily accessible anytime, anywhere. With our Cloud Surveillance systems, you can monitor your assets in real-time, providing peace of mind with advanced security features. Our Cloud PC solutions offer you the flexibility to work from anywhere, providing a virtual desktop experience tailored to your needs. Cloud File Transfer services streamline the sharing of large files securely and efficiently, facilitating seamless collaboration. With Drrived, our innovative platform, we bring all these services together, empowering businesses and individuals to harness the full potential of the cloud.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <PolicySection />
                
                {/* <FeaturesSection /> */}
                {/* <TeamSection /> */}
                <WhyUs />
                <CtaSection />
            </main>
            <Footer />
            <Scrollbar />
            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="7e90gBu4pas" onClose={() => setOpen(false)} />
        </Fragment>
    )
};
export default AboutUsPage;
