import React from 'react';
import loaderImg from "../images/assets/loader.gif"
function Loader() {
  return (
    <div className='position-absolute d-flex align-items-center justify-content-center w-100 h-100 start-0 top-0 bg-white z-1 opacity-75'>
      <img src={loaderImg} className='position-relative' alt="loader...."/>
    </div>
  )
}
export default Loader;
