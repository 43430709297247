import React, { useState, useEffect } from 'react'
import { validateEmail, validatePhone } from '../../utils/formValidation';
import { PostRequest } from '../../utils/request';
import { useNavigate } from 'react-router-dom';
import Loader from '../../utils/loader';
const ContactForm = (props) => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [forms, setForms] = useState({
        name: "",
        email: "",
        subject: "",
        message: "",
        phone: ""
    })
    const [error, setError] = useState({
        email: false,
        msg: false,
        phone: false,
        message: "",
        success: false,
    });
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const fullNameHandler = (e) => {
        const inputValue = e.target.value;
        const alphaValue = inputValue.replace(/[^A-Za-z\s]/g, '');
        if (alphaValue.length <= 30) {
            setForms(prevState => ({ ...prevState, name: alphaValue }));
            setError(prevState => ({ ...prevState, message: "" }));
        }
    };
    const handlePhoneInputChange = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/\D/g, "");
        if (numericValue.length <= 10) {
            setForms((prevState) => ({ ...prevState, phone: numericValue }));
            setError((prevState) => ({ ...prevState, phone: false, message: "" }));
        }
    };
    const handleEmailInputChange = (e) => {
        const inputValue = e.target.value;
        const val = inputValue.replace(" ", "");
        setForms((prevState) => ({ ...prevState, email: val }));
        setError((prevState) => ({ ...prevState, email: false, message: "" }));
    };
    const handleSubjectInputChange = (e) => {
        const inputValue = e.target.value;
        if (inputValue.length <= 50) {
            setForms((prevState) => ({ ...prevState, subject: inputValue }));
            setError((prevState) => ({ ...prevState, message: "" }));
        }
    };
    const handleMessageInputChange = (e) => {
        const inputValue = e.target.value;
        if (inputValue.length <= 500) {
            setForms((prevState) => ({ ...prevState, message: inputValue }));
            setError((prevState) => ({ ...prevState, message: "" }));
        }
    };
    function handleSubmitData(e) {
        e.preventDefault();
        setError((prevState) => ({ ...prevState, message: "" }));
        if (
            !forms.name || !forms.email || !forms.phone || !forms.subject || !forms.message) {
            setError((prevState) => ({
                ...prevState,
                success: false,
                message: "Fields must not be empty!",
            }));
        } else if (!validateEmail(forms.email)) {
            setError((prevState) => ({
                ...prevState,
                email: true,
                message: "Email is invalid!",
            }));
        } else if (!validatePhone(forms.phone)) {
            setError((prevState) => ({
                ...prevState,
                phone: true,
                message: "Phone number is invalid!",
            }));
        } else {
            setLoading(true);
            PostRequest(`${process.env.REACT_APP_URL}/contact/submit`, {
                fullName: forms.name,
                email: forms.email,
                phone: forms.phone,
                message: forms.message,
                subject: forms.subject,
            })
                .then((response) => {
                    setError((prevState) => ({ ...prevState, success: true }));
                    setLoading(false);
                    ClickHandler();
                    console.log("data<<<", response);
                    setForms((prevState) => ({
                        ...prevState,
                        name: "",
                        email: "",
                        subject: "",
                        phone: "",
                        message: "",
                    }));
                })
                .catch((err) => {
                    setError((prevState) => ({
                        ...prevState,
                        message: err?.data
                            ? err.data.error
                            : "Something went wrong. Try again later!",
                    }));
                });
        }
    }
    useEffect(() => {
        if (error.success) {
            navigate('/thanks');
            const timer = setTimeout(() => {
                setError((prevState) => ({ ...prevState, success: false }));
            }, 24000);
            return () => clearTimeout(timer);
        }
    }, [error.success, navigate]);
    return (
        <form onSubmit={handleSubmitData} >
            <p className={` mb-[10px] text-danger font-medium ${error.message ? 'block' : 'hidden'}`}>{error.message}</p>
            <div className="row">
                {loading && (
                    <Loader />
                )}
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="input_title" htmlFor="input_name">Full Name
                        </label>
                        <input
                            value={forms.name}
                            type="text"
                            name="name"
                            className="form-control"
                            onChange={fullNameHandler}
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="input_title" htmlFor="input_email">Your Email
                        </label>
                        <input
                            value={forms.email}
                            type="email"
                            name="email"
                            className="form-control"
                            // onBlur={(e) => changeHandler(e)}
                            onChange={handleEmailInputChange}
                        />
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-group">
                        <label className="input_title" htmlFor="input_phone">Your Phone</label>
                        <input
                            value={forms.phone}
                            type="phone"
                            name="phone"
                            className="form-control"
                            // onBlur={(e) => changeHandler(e)}
                            onChange={handlePhoneInputChange}
                        />
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-group">
                        <label className="input_title" htmlFor="input_phone">Subject</label>
                        <input
                            value={forms.subject}
                            type="text"
                            name="subject"
                            className="form-control"
                            // onBlur={(e) => changeHandler(e)}
                            onChange={handleSubjectInputChange}
                        />
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-group">
                        <label className="input_title" htmlFor="input_textarea">Message</label>
                        <textarea
                            // onBlur={(e) => changeHandler(e)}
                            onChange={handleMessageInputChange}
                            value={forms.message}
                            type="text"
                            name="message"
                            className="form-control"
                        >
                        </textarea>
                    </div>
                    <button type="submit" className="btn btn-primary">
                        <span className="btn_label" data-text="Send Message">Send Message</span>
                        <span className="btn_icon">
                            <i className="fa-solid fa-arrow-up-right"></i>
                        </span>
                    </button>
                </div>
            </div>
        </form>
    )
}

export default ContactForm;