import React from 'react';
import { BrowserRouter, Routes, Route, } from "react-router-dom";
// import Homepage from '../HomePage/HomePage'
// import HomePage2 from '../HomePage2/HomePage2';
// import HomePage3 from '../Home/Home';
// import PricingPage from '../PricingPage/PricingPage';
// import PortfolioPage from '../PortfolioPage/PortfolioPage';
// import PortfolioSinglePage from '../PortfolioSinglePage/PortfolioSinglePage';
// import TeamPage from '../TeamPage/TeamPage';
// import TeamSinglePage from '../TeamSinglePage/TeamSinglePage';
// import ServicePage from '../ServicePage/ServicePage';
import ServiceSinglePage from '../ServiceSinglePage/ServiceSinglePage';
// import BlogPage from '../BlogPage/BlogPage';
// import BlogDetails from '../BlogDetails/BlogDetails';
import Home from '../Home/Home';
import AboutUsPage from '../AboutUsPage/AboutUsPage';
import ContactPage from '../ContactPage/ContactPage';
import ServicePage from '../ServicePage/ServicePage';
import Thanks from '../Thanks/Thanks';
import PrivacyPolicyPage from '../PrivacyPolicy/PrivacyPolicyPage';
import TermsAndConditionsPage from '../TermsAndConditions/TermsAndConditionsPage';
import NotFoundPage from '../NotFoundPage/NotFoundPage';
// import Thanks from '../../components/ContactFrom/thank';

const AllRoute = () => {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="about" element={<AboutUsPage />} />
          <Route path="service" element={<ServicePage />} />
          <Route path="thanks" element={<Thanks />} />
          <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="terms-and-conditions" element={<TermsAndConditionsPage />} />
          {/* <Route path="/" element={<Homepage />} />
          <Route path="home" element={<Homepage />} />
          <Route path="home_software_company" element={<HomePage2 />} /> */}
          {/* 
          <Route path="pricing" element={<PricingPage />} />
          <Route path="portfolio" element={<PortfolioPage />} />
          <Route path="portfolio_details/:slug" element={<PortfolioSinglePage />} />
          <Route path="team" element={<TeamPage />} />
          <Route path="team-single/:slug" element={<TeamSinglePage />} />
          <Route path="blog" element={<BlogPage />} />
          <Route path="blog-single/:slug" element={<BlogDetails />} />   */}
          <Route path="service-single/:slug" element={<ServiceSinglePage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default AllRoute;
