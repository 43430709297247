import React from 'react';
import wImg from '../../images/about/about_image_5.webp'
import sIcon1 from '../../images/icons/icon_check_2.svg'
import sIcon2 from '../../images/icons/icon_leaf.svg'
import sIcon3 from '../../images/icons/icon_box.svg'
import sIcon4 from '../../images/icons/icon_receipt_add.svg'
import sIcon5 from '../../images/icons/icon_monitor.svg'
import sIcon6 from '../../images/icons/icon_microscope.svg'

const Policy = [
    {
        title: 'Scalability and flexibility',
        icon: sIcon1,
    },
    {
        title: 'Easy access from any device',
        icon: sIcon2,
    },
    {
        title: 'Reduced infrastructure costs',
        icon: sIcon3,
    },
    {
        title: 'Cost-effective for businesses',
        icon: sIcon4,
    },
    {
        title: 'Automatic backups and data recovery',
        icon: sIcon5,
    },
    {
        title: 'Easy collaboration among teams',
        icon: sIcon6,
    },


]


const WhyUs = (props) => {

    return (
        <section className="service_section section_space ">
            <div className="container">
                <div className="row align-items-center justify-content-lg-between">
                    <div className="col-lg-6">
                        <div className="image_wrap">
                            <img src={wImg} alt="Drrived - About" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="ps-lg-5">
                            <div className="heading_block mb-0">
                                <h2 className="heading_text ">Cloud Solutions Overview</h2>
                            </div>
                            <p className='mb-4'>Cloud Solutions Overview provides a summary of the various services and technologies available through cloud computing. It covers key solutions like cloud storage, computing power, virtual desktops, and file transfer services, all of which are delivered over the internet.</p>
                            <ul className="service_facilities_group unordered_list">
                                {Policy.map((policy, pitem) => (
                                    <li key={pitem}>
                                        <strong className="iconbox_block layout_icon_left">
                                            <span className="iconbox_icon">
                                                <img src={policy.icon} alt="Dollar SVG Icon" />
                                            </span>
                                            <span className="iconbox_content">
                                                <strong className="iconbox_title mb-0">{policy.title}</strong>
                                            </span>
                                        </strong>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default WhyUs;