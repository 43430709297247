import React, { Fragment } from 'react'
import Header from '../../components/header/Header'
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer'
import icon from '../../images/icons/icon_check_3.svg'

const PrivacyPolicyPage = props => {
  return (
    <Fragment>
      <Header />
      <main className='page_content about-page'>
        <PageTitle
          pageTitle={'Privacy Policy'}
          pagesub={'Policy 😍'}
          pageTop={'Privacy'}
        />

        <section className='portfolio_details_section section_space bg-light'>
          <div className='container'>
            <h2 className='details_item_title'>Introduction</h2>
            <p className='mb-4'>Welcome to Drrived privacy policy. We are committed to protecting your personal information and your right to privacy. This policy outlines how we collect, use, and protect your information when you use our Drrived services and visit our website https://www.drrived.com</p>

            <h2 className='details_item_title'>Information Collection</h2>
            <h3 className='mb-3 pt-2'>Types of Data Collected</h3>

            <ul className='icon_list unordered_list_block mb-4'>
              <li><span className='icon_list_icon' style={{ minWidth: '24px' }}><img src={icon} alt='Check SVG Icon' /></span>
                <span className='icon_list_text'><strong>Personal Data:</strong> Name, email address, phone number, payment information.</span>
              </li>
              <li><span className='icon_list_icon' style={{ minWidth: '24px' }}><img src={icon} alt='Check SVG Icon' /></span>
                <span className='icon_list_text'><strong>Usage Data:</strong> IP address, browser type, pages visited, time spent on pages, and other diagnostic data.
                </span>
              </li>
              <li><span className='icon_list_icon' style={{ minWidth: '24px' }}><img src={icon} alt='Check SVG Icon' /></span>
                <span className='icon_list_text'><strong>Video and Audio Data:</strong> Recorded footage, live stream data, audio recordings from your Drrived devices.
                </span>
              </li>
              <li><span className='icon_list_icon' style={{ minWidth: '24px' }}><img src={icon} alt='Check SVG Icon' /></span>
                <span className='icon_list_text'><strong>Device Data:</strong> Information about your devices, including model, operating system, and unique device identifiers.
                </span>
              </li>
            </ul>

            <h3 className='mb-3 pt-2'>Methods of Collection</h3>

            <ul className='icon_list unordered_list_block mb-4'>
              <li><span className='icon_list_icon' style={{ minWidth: '24px' }}><img src={icon} alt='Check SVG Icon' /></span>
                <span className='icon_list_text'><strong>Directly from You:</strong> When you register for an account, purchase our products, or contact us directly.
                </span>
              </li>
              <li><span className='icon_list_icon' style={{ minWidth: '24px' }}><img src={icon} alt='Check SVG Icon' /></span>
                <span className='icon_list_text'><strong>Automatically:</strong> Through cookies, web beacons, and similar tracking technologies when you use our website or services.
                </span>
              </li>
              <li><span className='icon_list_icon' style={{ minWidth: '24px' }}><img src={icon} alt='Check SVG Icon' /></span>
                <span className='icon_list_text'><strong>From Your Devices:</strong> When you use our Drrived devices, they automatically record and transmit data to our servers.
                </span>
              </li>
            </ul>

            <h2 className='details_item_title'>Use of Information</h2>

            <p className='mb-4'>We use the collected data for various purposes</p>

            <ul className='icon_list unordered_list_block mb-4'>
              <li><span className='icon_list_icon' style={{ minWidth: '24px' }}><img src={icon} alt='Check SVG Icon' /></span>
                <span className='icon_list_text'>To provide and maintain our services.</span>
              </li>
              <li><span className='icon_list_icon' style={{ minWidth: '24px' }}><img src={icon} alt='Check SVG Icon' /></span>
                <span className='icon_list_text'>To notify you about changes to our services.</span>
              </li>
              <li><span className='icon_list_icon' style={{ minWidth: '24px' }}><img src={icon} alt='Check SVG Icon' /></span>
                <span className='icon_list_text'>To allow you to participate in interactive features of our services.</span>
              </li>
              <li><span className='icon_list_icon' style={{ minWidth: '24px' }}><img src={icon} alt='Check SVG Icon' /></span>
                <span className='icon_list_text'>To provide customer support.</span>
              </li>
              <li><span className='icon_list_icon' style={{ minWidth: '24px' }}><img src={icon} alt='Check SVG Icon' /></span>
                <span className='icon_list_text'>To monitor the usage of our services.</span>
              </li>
              <li><span className='icon_list_icon' style={{ minWidth: '24px' }}><img src={icon} alt='Check SVG Icon' /></span>
                <span className='icon_list_text'>To detect, prevent, and address technical issues.</span>
              </li>
              <li><span className='icon_list_icon' style={{ minWidth: '24px' }}><img src={icon} alt='Check SVG Icon' /></span>
                <span className='icon_list_text'>To improve our products and services based on your feedback.</span>
              </li>
            </ul>

            <h2 className='details_item_title'>Data Sharing and Disclosure</h2>

            <p className='mb-4'>We may share your information in the following situations</p>

            <ul className='icon_list unordered_list_block mb-4'>
              <li><span className='icon_list_icon' style={{ minWidth: '24px' }}><img src={icon} alt='Check SVG Icon' /></span>
                <span className='icon_list_text'><strong>With Service Providers :</strong> We may share your information with third-party service providers who perform services on our behalf, such as payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.</span>
              </li>
              <li><span className='icon_list_icon' style={{ minWidth: '24px' }}><img src={icon} alt='Check SVG Icon' /></span>
                <span className='icon_list_text'><strong>For Legal Requirements :</strong> We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., a court or a government agency).</span>
              </li>
              <li><span className='icon_list_icon' style={{ minWidth: '24px' }}><img src={icon} alt='Check SVG Icon' /></span>
                <span className='icon_list_text'><strong>Business Transfers :</strong> In connection with or during negotiations of any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</span>
              </li>
            </ul>

            <h2 className='details_item_title'>Data Security</h2>

            <p className='mb-4'>We take the security of your data seriously and implement appropriate technical and organizational measures to protect your personal information. However, please note that no method of transmission over the Internet or method of electronic storage is 100% secure.</p>

            <h2 className='details_item_title'>User Rights</h2>

            <p className='mb-4'>You have the following rights regarding your data</p>

            <ul className='icon_list unordered_list_block mb-4'>
              <li><span className='icon_list_icon' style={{ minWidth: '24px' }}><img src={icon} alt='Check SVG Icon' /></span>
                <span className='icon_list_text'><strong>Access :</strong>  You have the right to access the personal data we hold about you.</span>
              </li>
              <li><span className='icon_list_icon' style={{ minWidth: '24px' }}><img src={icon} alt='Check SVG Icon' /></span>
                <span className='icon_list_text'><strong>Correction :</strong>  You have the right to correct any inaccuracies in your personal data.</span>
              </li>
              <li><span className='icon_list_icon' style={{ minWidth: '24px' }}><img src={icon} alt='Check SVG Icon' /></span>
                <span className='icon_list_text'><strong>Deletion :</strong>  You have the right to request the deletion of your personal data.</span>
              </li>
              <li><span className='icon_list_icon' style={{ minWidth: '24px' }}><img src={icon} alt='Check SVG Icon' /></span>
                <span className='icon_list_text'><strong>Data Portability :</strong>  You have the right to receive your personal data in a structured, commonly used, and machine-readable format.</span>
              </li>
              <li><span className='icon_list_icon' style={{ minWidth: '24px' }}><img src={icon} alt='Check SVG Icon' /></span>
                <span className='icon_list_text'><strong>Objection and Restriction :</strong>  You have the right to object to the processing of your personal data and to request the restriction of processing under certain circumstances.</span>
              </li>             
            </ul>

            <h2 className='details_item_title'>Cookies and Tracking Technologies</h2>

            <p className='mb-4'>We use cookies and similar tracking technologies to track the activity on our website and hold certain information. Cookies are files with a small amount of data which may include an anonymous unique identifier.</p>
            <p className='mb-4'>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our services.</p>

            <h2 className='details_item_title'>Third-Party Links</h2>

            <p className='mb-0'>Our services may contain links to other sites that are not operated by us. If you click on a third-party link, you will be directed to that third party's site.</p>
          </div>
        </section>
      </main>

      <Footer />
      <Scrollbar />
    </Fragment>
  )
}

export default PrivacyPolicyPage
