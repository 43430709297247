import React, { useState, useEffect } from 'react'
import AllRoute from '../router'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import drrivedIcon from '../../images/assets/drrived.png'

const App = () => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [])
  return (
    <div className='App' id='scrool'>
      {loading ? (
        <div id='preloader' class='preloader'>
          <div class='loader-circle'>
            <div class='loader-line-mask'>
              <div class='loader-line'></div>
            </div>
            <div class='loader-logo'>
              <img src={drrivedIcon} alt='Drrived' />
            </div>
          </div>
        </div>
      ) : (
        <AllRoute />
      )}
      <ToastContainer />
    </div>
  )
}

export default App
