import React from 'react';
// import sIcon1 from '../../images/icons/icon_clock.svg'
import sIcon2 from '../../images/icons/icon_dart_board_2.svg'
import sIcon3 from '../../images/icons/icon_target.svg'

const Policy = [
    // {
    //     title: 'Our History',
    //     subTitle: 'Since 1997 we have been a visionary and a reliable software engineering partner for world-class brands. abbreviated as co.',
    //     icon: sIcon1,
    // },
    {
        title: 'Our Mission',
        subTitle: "At Drrived, we are committed to ensuring the highest standards of data protection and data security. Safeguarding your personal and organizational information is not just a responsibility we uphold—it's the cornerstone of everything we do. As data becomes an increasingly valuable asset in today’s digital age, we prioritize protecting it with cutting-edge technologies and best practices.",
        icon: sIcon2,
    },
    {
        title: 'Our Vision',
        subTitle: 'At the core of our mission is the commitment to safeguarding data with the highest standards of security. Since our inception, we have consistently led the way in data protection, leveraging cutting-edge technology and innovative practices to ensure the confidentiality, integrity, and availability of the information we handle. As front-runners in the industry.',
        icon: sIcon3,
    },


]


const PolicySection = (props) => {

    return (
        <section className="policy_section section_space pt-0 bg-light">
            <div className="container">
                <div className="row">
                    {Policy.map((policy, pitem) => (
                        <div className="col-lg-6" key={pitem}>
                            <div className="iconbox_block">
                                <div className="iconbox_icon">
                                    <img src={policy.icon} alt="Dollar SVG Icon" />
                                </div>
                                <div className="iconbox_content">
                                    <h3 className="iconbox_title">{policy.title}</h3>
                                    <p className="mb-0">
                                        {policy.subTitle}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default PolicySection;