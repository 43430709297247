import React, { useRef, useEffect } from 'react';
import cloudFileStorage from '../../../images/about/cloud_file_storage.jpg'
import cloudFileTransfer from '../../../images/about/cloud_file_transfer.jpg'
import cloudSurveillance from '../../../images/about/cloud_surveillance.jpg'
import cloudPc from '../../../images/about/cloud_pc.jpg'
import sIcon1 from '../../../images/icons/icon_check_2.svg'
import Bg from '../../../images/backgrounds/bg_image_3.webp'
import { useLocation } from 'react-router-dom';

const ServicesNewSection = () => {

  const cloudFileStorageRef = useRef(null);
  const cloudSurveillanceRef = useRef(null);
  const cloudPCRef = useRef(null);
  const cloudFileTransferRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    if (location.hash === '#cloud-file-storage' && cloudFileStorageRef.current) {
      cloudFileStorageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    if (location.hash === '#cloud-surveillance' && cloudSurveillanceRef.current) {
      cloudSurveillanceRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    if (location.hash === '#cloud-pc' && cloudPCRef.current) {
      cloudPCRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    if (location.hash === '#cloud-file-transfer' && cloudFileTransferRef.current) {
      cloudFileTransferRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);

  return (
    <>
      <section id="cloud-file-storage" ref={cloudFileStorageRef} className="service_section section_space bg-light">
        <div className="container">
          <div className="row align-items-center justify-content-lg-between">
            <div className="col-lg-6">
              <div className="image_wrap">
                <img className='rounded' src={cloudFileStorage} alt="Cloud File Storage" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="ps-lg-5">
                <div className="heading_block mb-0">
                  <h2 className="heading_text">
                    Cloud File Storage
                  </h2>
                </div>
                <p>Through Cloud File Storage, you can store data in the cloud that provides servers and applications access to data through shared file systems. This compatibility makes cloud file storage ideal for workloads that rely on shared file systems and provides simple integration without code changes.</p>
                <ul className="service_facilities_group unordered_list">
                  <li>
                    <strong className="iconbox_block layout_icon_left">
                      <span className="iconbox_icon">
                        <img src={sIcon1} alt="Dollar SVG Icon" />
                      </span>
                      <span className="iconbox_content">
                        <strong className="iconbox_title mb-0">Secure Data Encryption</strong>
                      </span>
                    </strong>
                  </li>
                  <li>
                    <strong className="iconbox_block layout_icon_left">
                      <span className="iconbox_icon">
                        <img src={sIcon1} alt="Dollar SVG Icon" />
                      </span>
                      <span className="iconbox_content">
                        <strong className="iconbox_title mb-0">Unlimited Storage Scalability </strong>
                      </span>
                    </strong>
                  </li>
                  <li>
                    <strong className="iconbox_block layout_icon_left">
                      <span className="iconbox_icon">
                        <img src={sIcon1} alt="Dollar SVG Icon" />
                      </span>
                      <span className="iconbox_content">
                        <strong className="iconbox_title mb-0">Automated Data Backup and DR</strong>
                      </span>
                    </strong>
                  </li>
                  <li>
                    <strong className="iconbox_block layout_icon_left">
                      <span className="iconbox_icon">
                        <img src={sIcon1} alt="Dollar SVG Icon" />
                      </span>
                      <span className="iconbox_content">
                        <strong className="iconbox_title mb-0">On-Premises and Cloud Access</strong>
                      </span>
                    </strong>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id='cloud-pc' ref={cloudPCRef} className="service_section section_space">
        <div className="container">
          <div className="row align-items-center justify-content-lg-between flex-row-reverse">
            <div className="col-lg-6">
              <div className="image_wrap">
                <img className='rounded' src={cloudPc} alt="Cloud PC" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="ps-lg-5">
                <div className="heading_block mb-0">
                  <h2 className="heading_text">
                    Cloud PC
                  </h2>
                </div>
                <p>Your cloud PC lives on a remote server in a data center. You connect to it via the internet, and your desktop, files, and applications are available to you on any device, anytime, anywhere. It allows you to use multiple operating systems, access and run any heavy application with the same native experience you expect from physical computers.</p>
                <ul className="service_facilities_group unordered_list">
                  <li>
                    <strong className="iconbox_block layout_icon_left">
                      <span className="iconbox_icon">
                        <img src={sIcon1} alt="Dollar SVG Icon" />
                      </span>
                      <span className="iconbox_content">
                        <strong className="iconbox_title mb-0">Seamless Remote Access</strong>
                      </span>
                    </strong>
                  </li>
                  <li>
                    <strong className="iconbox_block layout_icon_left">
                      <span className="iconbox_icon">
                        <img src={sIcon1} alt="Dollar SVG Icon" />
                      </span>
                      <span className="iconbox_content">
                        <strong className="iconbox_title mb-0">Scalable Virtual Desktops</strong>
                      </span>
                    </strong>
                  </li>
                  <li>
                    <strong className="iconbox_block layout_icon_left">
                      <span className="iconbox_icon">
                        <img src={sIcon1} alt="Dollar SVG Icon" />
                      </span>
                      <span className="iconbox_content">
                        <strong className="iconbox_title mb-0">High-Performance GPU Computing</strong>
                      </span>
                    </strong>
                  </li>
                  <li>
                    <strong className="iconbox_block layout_icon_left">
                      <span className="iconbox_icon">
                        <img src={sIcon1} alt="Dollar SVG Icon" />
                      </span>
                      <span className="iconbox_content">
                        <strong className="iconbox_title mb-0">Enhanced Data Security</strong>
                      </span>
                    </strong>
                  </li>
                  <li>
                    <strong className="iconbox_block layout_icon_left">
                      <span className="iconbox_icon">
                        <img src={sIcon1} alt="Dollar SVG Icon" />
                      </span>
                      <span className="iconbox_content">
                        <strong className="iconbox_title mb-0">Cost Effective</strong>
                      </span>
                    </strong>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id='cloud-surveillance' ref={cloudSurveillanceRef} className="business_consulting_service_section section_space" style={{ backgroundImage: `url(${Bg})` }}>
        <div className="container">
          <div className="row align-items-center justify-content-lg-between">
            <div className="col-lg-6">
              <div className="image_wrap">
                <img className='rounded' src={cloudSurveillance} alt="Cloud Surveillance" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="ps-lg-5">
                <div className="heading_block mb-0">
                  <h2 className="heading_text">
                    Cloud Surveillance
                  </h2>
                </div>
                <p>A system that allows users to access video recordings and live feeds remotely from the internet.</p>
                <ul className="service_facilities_group unordered_list">
                  <li>
                    <strong className="iconbox_block layout_icon_left">
                      <span className="iconbox_icon">
                        <img src={sIcon1} alt="Dollar SVG Icon" />
                      </span>
                      <span className="iconbox_content">
                        <strong className="iconbox_title mb-0">Real-Time Video Monitoring</strong>
                      </span>
                    </strong>
                  </li>
                  <li>
                    <strong className="iconbox_block layout_icon_left">
                      <span className="iconbox_icon">
                        <img src={sIcon1} alt="Dollar SVG Icon" />
                      </span>
                      <span className="iconbox_content">
                        <strong className="iconbox_title mb-0">Cloud-Based Footage Storage</strong>
                      </span>
                    </strong>
                  </li>
                  <li>
                    <strong className="iconbox_block layout_icon_left">
                      <span className="iconbox_icon">
                        <img src={sIcon1} alt="Dollar SVG Icon" />
                      </span>
                      <span className="iconbox_content">
                        <strong className="iconbox_title mb-0">Alerts and Threat Protection</strong>
                      </span>
                    </strong>
                  </li>
                  <li>
                    <strong className="iconbox_block layout_icon_left">
                      <span className="iconbox_icon">
                        <img src={sIcon1} alt="Dollar SVG Icon" />
                      </span>
                      <span className="iconbox_content">
                        <strong className="iconbox_title mb-0">Secure Remote Access</strong>
                      </span>
                    </strong>
                  </li>
                  <li>
                    <strong className="iconbox_block layout_icon_left">
                      <span className="iconbox_icon">
                        <img src={sIcon1} alt="Dollar SVG Icon" />
                      </span>
                      <span className="iconbox_content">
                        <strong className="iconbox_title mb-0">On-Premises and Cloud Access</strong>
                      </span>
                    </strong>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id='cloud-file-transfer' ref={cloudFileTransferRef} className="service_section section_space">
        <div className="container">
          <div className="row align-items-center justify-content-lg-between flex-row-reverse">
            <div className="col-lg-6">
              <div className="image_wrap">
                <img className='rounded' src={cloudFileTransfer} alt="Cloud File Transfer" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="ps-lg-5">
                <div className="heading_block mb-0">
                  <h2 className="heading_text">
                    Cloud File Transfer
                  </h2>
                </div>
                <p>Cloud File Transfer (CFT) is a centralised, secure platform for managing file transfers across zones, streamlining the process for WOG entities and stakeholders. It supports secure file transfers via HTTPS and SFTP across both Internet and Intranet environments, ensuring flexible connectivity options.</p>
                <ul className="service_facilities_group unordered_list">
                  <li>
                    <strong className="iconbox_block layout_icon_left">
                      <span className="iconbox_icon">
                        <img src={sIcon1} alt="Dollar SVG Icon" />
                      </span>
                      <span className="iconbox_content">
                        <strong className="iconbox_title mb-0">Fast Data Transfer</strong>
                      </span>
                    </strong>
                  </li>
                  <li>
                    <strong className="iconbox_block layout_icon_left">
                      <span className="iconbox_icon">
                        <img src={sIcon1} alt="Dollar SVG Icon" />
                      </span>
                      <span className="iconbox_content">
                        <strong className="iconbox_title mb-0">End-to-End Encryptton</strong>
                      </span>
                    </strong>
                  </li>
                  <li>
                    <strong className="iconbox_block layout_icon_left">
                      <span className="iconbox_icon">
                        <img src={sIcon1} alt="Dollar SVG Icon" />
                      </span>
                      <span className="iconbox_content">
                        <strong className="iconbox_title mb-0">Large File Support</strong>
                      </span>
                    </strong>
                  </li>
                  <li>
                    <strong className="iconbox_block layout_icon_left">
                      <span className="iconbox_icon">
                        <img src={sIcon1} alt="Dollar SVG Icon" />
                      </span>
                      <span className="iconbox_content">
                        <strong className="iconbox_title mb-0">Cross-Platform Compatibility</strong>
                      </span>
                    </strong>
                  </li>
                  <li>
                    <strong className="iconbox_block layout_icon_left">
                      <span className="iconbox_icon">
                        <img src={sIcon1} alt="Dollar SVG Icon" />
                      </span>
                      <span className="iconbox_content">
                        <strong className="iconbox_title mb-0">Global Access Availability</strong>
                      </span>
                    </strong>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ServicesNewSection;