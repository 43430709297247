import React, { useState, useEffect } from 'react'
import Bg from '../../../images/backgrounds/bg_image_5.webp'
import { validateEmail, validatePhone } from '../../../utils/formValidation';
import { PostRequest } from '../../../utils/request';
import Loader from '../../../utils/loader';
import { useNavigate } from 'react-router-dom';
const BusinessContact = (props) => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [forms, setForms] = useState({
        name: "",
        email: "",
        companyName: "",
        message: "",
        phone: ""
    })
    const [error, setError] = useState({
        email: false,
        msg: false,
        phone: false,
        message: "",
        success: false,
    });
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }
    const fullNameHandler = (e) => {
        const inputValue = e.target.value;
        const alphaValue = inputValue.replace(/[^A-Za-z\s]/g, '');
        if (alphaValue.length <= 30) {
            setForms(prevState => ({ ...prevState, name: alphaValue }));
            setError(prevState => ({ ...prevState, message: "" }));
        }
    };
    const handlePhoneInputChange = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/\D/g, "");
        if (numericValue.length <= 10) {
            setForms((prevState) => ({ ...prevState, phone: numericValue }));
            setError((prevState) => ({ ...prevState, phone: false, message: "" }));
        }
    };
    const handleEmailInputChange = (e) => {
        const inputValue = e.target.value;
        const val = inputValue.replace(" ", "");
        setForms((prevState) => ({ ...prevState, email: val }));
        setError((prevState) => ({ ...prevState, email: false, message: "" }));
    };
    const handleSubjectInputChange = (e) => {
        const inputValue = e.target.value;
        if (inputValue.length <= 50) {
            setForms((prevState) => ({ ...prevState, companyName: inputValue }));
            setError((prevState) => ({ ...prevState, message: "" }));
        }
    };
    const handleMessageInputChange = (e) => {
        const inputValue = e.target.value;
        if (inputValue.length <= 500) {
            setForms((prevState) => ({ ...prevState, message: inputValue }));
            setError((prevState) => ({ ...prevState, message: "" }));
        }
    };
    function handleSubmit(e) {
        e.preventDefault();
        setError((prevState) => ({ ...prevState, message: "" }));
        if (
            !forms.name || !forms.email || !forms.phone || !forms.companyName || !forms.message) {
            setError((prevState) => ({
                ...prevState,
                success: false,
                message: "Fields must not be empty!",
            }));
        } else if (!validateEmail(forms.email)) {
            setError((prevState) => ({
                ...prevState,
                email: true,
                message: "Email is invalid!",
            }));
        } else if (!validatePhone(forms.phone)) {
            setError((prevState) => ({
                ...prevState,
                phone: true,
                message: "Phone number is invalid!",
            }));
        } else {
            setLoading(true);
            PostRequest(`${process.env.REACT_APP_URL}/query`, {
                fullName: forms.name,
                email: forms.email,
                phone: forms.phone,
                message: forms.message,
                companyName: forms.companyName,
            })
                .then((response) => {
                    setError((prevState) => ({ ...prevState, success: true }));
                    setLoading(false);
                    ClickHandler();
                    console.log("data<<<", response);
                    setForms((prevState) => ({
                        ...prevState,
                        name: "",
                        email: "",
                        companyName: "",
                        phone: "",
                        message: "",
                    }));
                })
                .catch((err) => {
                    setError((prevState) => ({
                        ...prevState,
                        message: err?.data
                            ? err.data.error
                            : "Something went wrong. Try again later!",
                    }));
                });
        }
    }
    useEffect(() => {
        if (error.success) {
            navigate('/thanks');
            const timer = setTimeout(() => {
                setError((prevState) => ({ ...prevState, success: false }));
            }, 24000);
            return () => clearTimeout(timer);
        }
    }, [error.success, navigate]);
    return (

        <section className="calltoaction_section parallaxie" style={{ backgroundImage: `url(${Bg})` }}>
            <div className="container">
                <div className="row justify-content-lg-end">
                    <div className="col-lg-7 position-relative">
                        <div className="instant_contact_form bg-primary shadow-none">
                            <div className="small_title text-white">
                                <i className="fa-solid fa-envelope-open-text text-white"></i>
                                Reseller / Distributor Query
                            </div>
                            <h3 className="form_title text-white">
                                Send us your query, and we'll promptly respond you.
                            </h3>
                            <p className={` mb-[10px] text-danger font-medium ${error.message ? 'block' : 'hidden'}`}>{error.message}</p>
                            <form className="xb-item--form contact-from" onSubmit={handleSubmit}>
                                <div className="row">
                                    {loading && (
                                        <Loader />
                                    )}
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="input_title" htmlFor="input_name">
                                                <i className="fa-regular fa-user"></i>
                                            </label>
                                            <input
                                                value={forms.name}
                                                type="text"
                                                name="name"
                                                className="form-control"
                                                onChange={fullNameHandler}
                                                placeholder="Your Name" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="input_title" htmlFor="input_email">
                                                <i className="fa-regular fa-envelope"></i>
                                            </label>
                                            <input
                                                value={forms.email}
                                                type="email"
                                                name="email"
                                                className="form-control"
                                                onChange={handleEmailInputChange}
                                                placeholder="Your Email" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="input_title" htmlFor="input_phone">
                                                <i className="fa-regular fa-phone-volume"></i>
                                            </label>
                                            <input
                                                value={forms.phone}
                                                type="phone"
                                                name="phone"
                                                className="form-control"
                                                onChange={handlePhoneInputChange}
                                                placeholder="Your Phone No." />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="input_title" htmlFor="input_company">
                                                <i className="fa-regular fa-globe"></i>
                                            </label>
                                            <input
                                                value={forms.companyName}
                                                type="text"
                                                name="companyname"
                                                className="form-control"
                                                onChange={handleSubjectInputChange}
                                                placeholder="Your Company Name" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="input_title" htmlFor="input_textarea">
                                                <i className="fa-regular fa-comments"></i>
                                            </label>
                                            <textarea
                                                onChange={handleMessageInputChange}
                                                value={forms.message}
                                                type="text"
                                                name="message"
                                                className="form-control"
                                                placeholder="How can we help you?">
                                            </textarea>
                                        </div>
                                        <button type="submit" className="btn btn-primary">
                                            <span className="btn_label" data-text="Send Request">Send Request</span>
                                            <span className="btn_icon">
                                                <i className="fa-solid fa-arrow-up-right"></i>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default BusinessContact;