import React, { Fragment } from 'react'
import Header from '../../components/header/Header'
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer'
import icon from '../../images/icons/icon_check_3.svg'

const TermsAndConditionsPage = props => {
  return (
    <Fragment>
      <Header />
      <main className='page_content about-page'>
        <PageTitle
          pageTitle={'Terms and Conditions'}
          pagesub={'Conditions 😍'}
          pageTop={'Terms and'}
        />

        <section className='portfolio_details_section section_space bg-light'>
          <div className='container'>
            <h2 className='details_item_title'>Introduction</h2>
            <p className='mb-4'>Welcome to Drrived. These Terms & Conditions govern your use of our website https://www.drrived.com and our drrived products and services. By accessing or using our services, you agree to be bound by these terms.</p>

            <h2 className='details_item_title'>Definitions</h2>

            <ul className='icon_list unordered_list_block mb-4'>
              <li><span className='icon_list_icon' style={{ minWidth: '24px' }}><img src={icon} alt='Check SVG Icon' /></span>
                <span className='icon_list_text'><strong>Service:</strong> Refers to the website and drrived products operated by Drrived.</span>
              </li>
              <li><span className='icon_list_icon' style={{ minWidth: '24px' }}><img src={icon} alt='Check SVG Icon' /></span>
                <span className='icon_list_text'><strong>User:</strong> Any individual or entity using our Service.
                </span>
              </li>
              <li><span className='icon_list_icon' style={{ minWidth: '24px' }}><img src={icon} alt='Check SVG Icon' /></span>
                <span className='icon_list_text'><strong>Content:</strong> All text, information, images, video, audio, and other data available through our Service.
                </span>
              </li>
            </ul>

            <h2 className='details_item_title'>Acceptance of Terms</h2>

            <p className='mb-4'>By accessing or using our Service, you agree to comply with and be bound by these Terms & Conditions. If you do not agree to these terms, you may not use the Service.</p>
         
            <h2 className='details_item_title'>User Accounts</h2>

            <p className='mb-4'>To access certain features of our Service, you may be required to create an account. You agree to provide accurate and complete information during the registration process and to keep this information up to date. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</p>
          
            <h2 className='details_item_title'>Use of the Service</h2>

            <p className='mb-4'>You agree to use the Service only for lawful purposes and in accordance with these Terms & Conditions. You agree not to</p>

            <ul className='icon_list unordered_list_block mb-4'>
              <li>
                <span className='icon_list_icon' style={{ minWidth: '24px' }}>
                  <img src={icon} alt='Check SVG Icon' />
                </span>
                <span className='icon_list_text'>Use the Service in any way that violates any applicable local, state, national, or international law or regulation.</span>
              </li>
              <li>
                <span className='icon_list_icon' style={{ minWidth: '24px' }}>
                  <img src={icon} alt='Check SVG Icon' />
                </span>
                <span className='icon_list_text'>Engage in any conduct that restricts or inhibits anyone’s use or enjoyment of the Service, or which, as determined by us, may harm Drrived or users of the Service.</span>
              </li>
              <li>
                <span className='icon_list_icon' style={{ minWidth: '24px' }}>
                  <img src={icon} alt='Check SVG Icon' />
                </span>
                <span className='icon_list_text'>Use the Service to exploit, harm, or attempt to exploit or harm minors in any way.</span>
              </li>
              <li>
                <span className='icon_list_icon' style={{ minWidth: '24px' }}>
                  <img src={icon} alt='Check SVG Icon' />
                </span>
                <span className='icon_list_text'>Introduce any viruses, trojans, worms, logic bombs, or other material that is malicious or technologically harmful.</span>
              </li>
              <li>
                <span className='icon_list_icon' style={{ minWidth: '24px' }}>
                  <img src={icon} alt='Check SVG Icon' />
                </span>
                <span className='icon_list_text'>Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Service, the server on which the Service is stored, or any server, computer, or database connected to the Service.</span>
              </li>
            </ul>


            <h2 className='details_item_title'>Intellectual Property</h2>

            <p className='mb-4'>The Service and its original content, features, and functionality are and will remain the exclusive property of Drrived and its licensors. The Service is protected by copyright, trademark, and other laws of both the United States and foreign countries.</p>
            
            <h2 className='details_item_title'>User-Generated Content</h2>
            <p className='mb-4'>If you upload or share any content through our Service, you grant Drrived a worldwide, non-exclusive, royalty-free, perpetual, and transferable license to use, reproduce, distribute, prepare derivative works of, display, and perform that content in connection with the Service.</p>
            
            <h2 className='details_item_title'>Privacy</h2>
            <p className='mb-4'>Your use of the Service is also governed by our Privacy Policy. Please review our Privacy Policy, which describes how we collect, use, and protect your personal information.</p>
            
            <h2 className='details_item_title'>Termination</h2>
            <p className='mb-4'>We may terminate or suspend your account and bar access to the Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms & Conditions. Upon termination, your right to use the Service will cease immediately.</p>
            
            <h2 className='details_item_title'>Limitation of Liability</h2>
            <p className='mb-4'>In no event shall Drrived, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from</p>
            
            <ul className='icon_list unordered_list_block mb-4'>
              <li>
                <span className='icon_list_icon' style={{ minWidth: '24px' }}>
                  <img src={icon} alt='Check SVG Icon' />
                </span>
                <span className='icon_list_text'>your use or inability to use the Service</span>
              </li>
              <li>
                <span className='icon_list_icon' style={{ minWidth: '24px' }}>
                  <img src={icon} alt='Check SVG Icon' />
                </span>
                <span className='icon_list_text'>any unauthorized access to or use of our servers and/or any personal information stored therein</span>
              </li>
              <li>
                <span className='icon_list_icon' style={{ minWidth: '24px' }}>
                  <img src={icon} alt='Check SVG Icon' />
                </span>
                <span className='icon_list_text'>any interruption or cessation of transmission to or from the Service</span>
              </li>
              <li>
                <span className='icon_list_icon' style={{ minWidth: '24px' }}>
                  <img src={icon} alt='Check SVG Icon' />
                </span>
                <span className='icon_list_text'>any bugs, viruses, trojans, or the like that may be transmitted to or through our Service by any third party</span>
              </li>
              <li>
                <span className='icon_list_icon' style={{ minWidth: '24px' }}>
                  <img src={icon} alt='Check SVG Icon' />
                </span>
                <span className='icon_list_text'> any errors or omissions in any content or for any loss or damage incurred as a result of your use of any content posted, emailed, transmitted, or otherwise made available through the Service, whether based on warranty, contract, tort (including negligence), or any other legal theory, and whether or not we have been informed of the possibility of such damage.</span>
              </li>              
            </ul>

            <h2 className='details_item_title'>Governing Law</h2>
            <p className='mb-4'>These Terms & Conditions shall be governed and construed in accordance with the laws of India, without regard to its conflict of law provisions.</p>
            
            <h2 className='details_item_title'>Changes to Terms & Conditions</h2>
            <p className='mb-4'>We reserve the right to modify or replace these Terms & Conditions at any time. If a revision is material, we will try to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>
            
            <h2 className='details_item_title'>Contact Us</h2>
            <p className='mb-4'>If you have any questions about these Terms & Conditions, please contact us at <a href="mailto:contact@drrived.com"><span className="icon_list_text">support@drrived.com</span></a></p>
            
           </div>
        </section>
      </main>

      <Footer />
      <Scrollbar />
    </Fragment>
  )
}

export default TermsAndConditionsPage
