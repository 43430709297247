import React, { useEffect, useState } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import './style.css'

const Scrollbar = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const toggleVisibility = () => {
            setIsVisible(window.scrollY > 150);
        };

        window.addEventListener('scroll', toggleVisibility);
        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);
    
    return (
        <>
        {isVisible && (
            <ul className="smothscroll">
                <li><AnchorLink href='#scrool'><i className="ti-arrow-up"></i></AnchorLink></li>
            </ul>
            )}
        </>
    )
}

export default Scrollbar;
