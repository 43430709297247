import React, { Fragment } from 'react';
import Hero from '../../components/hero/hero';
// import PartnerSection from '../../components/business-consulting-components/PartnerSection/PartnerSection';
// import EmpoweringSuccess from '../../components/business-consulting-components/EmpoweringSuccess/EmpoweringSuccess';
// import ConsultingService from '../../components/business-consulting-components/ConsultingService/ConsultingService';
import BusinessTestimonial from '../../components/business-consulting-components/BusinessTestimonial/BusinessTestimonial';
// import BusinessBlogSection from '../../components/business-consulting-components/BusinessBlogSection/BusinessBlogSection';
import BusinessContact from '../../components/business-consulting-components/BusinessContact/BusinessContact';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import ServicesNewSection from '../../components/business-consulting-components/ServicesNewSection/ServicesNewSection';


const Home = () => {

    return (
        <Fragment>
            <div className='index_business_consulting'>
                <main className="page_content s2">
                    <Header />
                    <Hero />
                    {/* <PartnerSection /> */}
                    {/* <EmpoweringSuccess /> */}
                    <ServicesNewSection />
                    {/* <ConsultingService /> */}
                    <BusinessTestimonial />
                    {/* <BusinessBlogSection /> */}
                    <BusinessContact />
                    <Footer />
                    <Scrollbar />
                </main>
            </div>
        </Fragment>
    )
};
export default Home;