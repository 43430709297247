import React, { Fragment } from 'react'
import Header from '../../components/header/Header'
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer'
import { Link } from 'react-router-dom'

const NotFoundPage = props => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
}
  return (
    <Fragment>
      <Header />
      <main className='page_content about-page'>
        <PageTitle
          pageTitle={'Error 404'}
          pagesub={'404 😍'}
          pageTop={'Error'}
        />
        <section className='error404 section_space bg-light'>
          <div class='container not-found-content'>
            <div class='row justify-content-center'>
              <div class='col-lg-12'>
                <div class='contant-wrapper text-center'>
                  <div class='text-404'>
                    <h2>404</h2>
                  </div>
                  <div class='error-page__content mb-50'>
                    <h2>Hi 👋 Sorry We Can’t Find That Page!</h2>
                    <p>Oops! The page you are looking for does not exist. It
                      might have been moved or deleted.</p>

                    <div class='error-page-button'>
                      <Link onClick={ClickHandler} to="/" className="btn btn-primary">
                          <span className="btn_label" data-text="Go Back Home">Go Back Home</span>
                          <span className="btn_icon">
                              <i className="fa-solid fa-arrow-up-right"></i>
                          </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer />
      <Scrollbar />
    </Fragment>
  )
}

export default NotFoundPage
